import Client, { ImageSearch, GifSearch } from "grow.client";
import { ImageSearchGrid } from "./ImageSearchGrid";
import Link from '@mui/material/Link';

interface Props {
    columnCount: 2 | 3
    scrollableTargetId: string
    selectImage: (photo: ImageSearch | GifSearch) => void
}

export const GifSearchGrid: React.FC<Props> = ({ columnCount, scrollableTargetId, selectImage }) => {
    const getGifs = (query: string | null | undefined, page: number | undefined) => {
        const client = new Client();
        return client.getGifs(query, page);
    }

    return (
        <ImageSearchGrid
            columnCount={columnCount}
            getImages={getGifs}
            scrollableTargetId={scrollableTargetId}
            selectImage={selectImage}
            title={(
                <Link href="https://tenor.com" target="_blank" >
                    <img src="/assets/images/tenor.png" alt="Powered by Tenor" style={{ width: 150 }} />
                </Link>
            )}
        />
    )
}